import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import InputHandler from '../../../../UIComponents/InputHandler'
import SelectBoxHandler from '../../../../UIComponents/SelectBoxHandler'
import CheckBoxHandler from '../../../../UIComponents/CheckBoxHandler'
import RadioButtonHandler from '../../../../UIComponents/RadioButtonHandler'
import TextareaHandler from '../../../../UIComponents/TextareaHandler'
import FileUploadHandler from '../../../../UIComponents/FileUploadHandler'
import FormSubmissionHandler from '../../../../UIComponents/FormSubmissionHandler'
import { postAPI } from '../../../../utils/Api';
import { store } from '../../../../app/store'


export default function Form() {
  const user = store.getState().auth.user;
  const {id} = useParams();
  const InitialFormData = {
    id: id, 
    org_id: user?.org_id,
    payment_mode: 'ONLINE',
    payment_for: 'ADMISSIONFEE'
  }
  const [form_data, setFormData] = useState(InitialFormData);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});


  // Radio Button
  const PaymentModeOptions = [{
    label: 'ONLINE',
    value: 'ONLINE'
  }, {
    label: 'OFFLINE',
    value: 'OFFLINE'
  }]

  // Radio Button
  const PaymentForOptions = [{
    label: 'ADMISSIONFEE',
    value: 'ADMISSIONFEE'
  }, {
    label: 'SESSIONFEE',
    value: 'SESSIONFEE'
  }, {
    label: 'MONTHLYFEE',
    value: 'MONTHLYFEE'
  }]



  useEffect(async () => {
    if(id) {
      const data = await postAPI('/Payment/fetchSingle', {id: id, org_id: user?.org_id});
      const PaymentData = data?.data[0];
      setFormData({...PaymentData, id: id})
    }
  }, [user])


  const handleSubmit = async e => {
    e.preventDefault()
    if(id) {
      const data = await postAPI('/Payment/update', form_data);
    } else {
      const data = await postAPI('/Payment/create', form_data);
    }
  }

  return (
    <>
      <div className='container-fluid'>
        <div className="card mb-3">
          <div className="card-body">
            <FormSubmissionHandler handleSubmit={handleSubmit} editing={editing} error={error}>
              {/* <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <SelectBoxHandler Label="For Class" Options={SectionList} Name={"for_class"} setFormData={setFormData} form_data={form_data} MultiSelection={false}></SelectBoxHandler>
                  </div>
                  <div className='col'>
                    <SelectBoxHandler Label="For Section" Options={MenuList} Name={"for_section"} setFormData={setFormData} form_data={form_data} MultiSelection={false}></SelectBoxHandler>
                  </div>
                </div>
              </div> */}
              <div className='container-fluid'>
                <InputHandler form_data={form_data} setFormData={setFormData} Name="payment_name" Type="text" Label="Payment Title"></InputHandler>
              </div>
              <div className='container-fluid'>
                <InputHandler form_data={form_data} setFormData={setFormData} Name="total_payment_amount" Type="number" Label="Total Payment Amount"></InputHandler>
              </div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <RadioButtonHandler Label="Payment Mode" Options={PaymentModeOptions} Name={"payment_mode"} setFormData={setFormData} form_data={form_data}></RadioButtonHandler>
                  </div>
                  <div className='col'>
                    <RadioButtonHandler Label="Payment For" Options={PaymentForOptions} Name={"payment_for"} setFormData={setFormData} form_data={form_data}></RadioButtonHandler>
                  </div>
                </div>
              </div>
            </FormSubmissionHandler>
          </div>
        </div>
      </div>
    </>
  )
}
