import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InputHandler from '../../../../UIComponents/InputHandler';
import TextareaHandler from '../../../../UIComponents/TextareaHandler';
import FileUploadHandler from '../../../../UIComponents/FileUploadHandler';
import FormSubmissionHandler from '../../../../UIComponents/FormSubmissionHandler';
import { postAPI } from '../../../../utils/Api';
import { store } from '../../../../app/store';

export default function Form() {
  const user = store.getState().auth.user;
  const { id } = useParams();
  const initialFormData = {
    id: id,
    org_id: user?.org_id,
    // lead_from: "GOOGLE",
    page: "Slider"
  };

  const [form_data, setFormData] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    const fetchData = async (Id) => {
      try {
        const data = await postAPI('/Slider/fetchSingle', { id: Id, org_id: user?.org_id });
        const sliderData = data?.data[0];
        setFormData(sliderData);
        console.log("form_data", form_data, sliderData);
      } catch (err) {
        setError({ fetchError: 'Error fetching data' });
      }
    };
    if (id) {
      fetchData(id);
    } else {
      setFormData(initialFormData)
    }
  }, [id, user?.org_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  console.log("form_data", form_data);

    try {
      if (id) {
        await postAPI('/Slider/update', form_data);
      } else {
        await postAPI('/Slider/create', form_data);
      }
    } catch (err) {
      setError({ submitError: 'Error submitting form' });
    }
  };


  return (
    <div className='container-fluid'>
      <div className="card mb-3">
        <div className="card-body">
          <FormSubmissionHandler handleSubmit={handleSubmit} error={error}>
            <div className='container-fluid'>
              <InputHandler form_data={form_data} setFormData={setFormData} Name="title" Type="text" Label="Title" />
              <TextareaHandler form_data={form_data} setFormData={setFormData} Name="subtitle" Type="text" Label="Subtitle" />
              <FileUploadHandler Name="slider_image" form_data={form_data} setFormData={setFormData} error={error} setError={setError} Section='SliderImage' />
            </div>
          </FormSubmissionHandler>
        </div>
      </div>
    </div>
  );
}
