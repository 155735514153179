// import React, { useEffect, useState } from 'react'
// import { postAPIForm, getURL } from '../utils/Api';

// export default function FileUploadHandler({ Name = "file", form_data, setFormData, error, setError, Section = 'Files' }) {
//     console.log(form_data)
//     const [preview, setPreview] = useState(form_data[Name] ? form_data[Name] : null);

//     const handleImageChange = async (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             const formData = new FormData();
//             formData.append('baseDir', Section);
//             formData.append('filename', 'file');
//             formData.append('original_file', file);
//             const data = await postAPIForm('/file/uploadFile', formData);
//             if (data.status == 0) {
//                 setError({ status: 0, msg: 'Image Not Uploaded' });
//                 return;
//             } else {
//                 setPreview(data.filepath);
//                 setFormData({ ...form_data, [Name]: data.filepath })
//             }
//         }
//     };
//     return (
//         <>
//             <div className="form-group mt-3">
//                 <label className="" for={Name}>Upload</label>
//                 <input type="file" className="form-control file" id={Name} onChange={handleImageChange} />
//             </div>
//             {/* <div className="form-group">
//                 <label>Upload File</label>
//                 <label className="upload-file">
//                     <input
//                         type="file"
//                         className="file"
                        
//                     />
//                     <span>Click here to upload file</span>
//                 </label>
//             </div> */}

//             {preview && (
//                 <div className="view-image">
//                     <div className="close-icon" onClick={() => setPreview(null)}>x</div>
//                     <img src={`${getURL()}/${preview}`} width={100} height={100} alt="Image Preview" />
//                 </div>
//             )}
//         </>
//     )
// }









import React, { useEffect, useState } from 'react';
import { postAPIForm, getURL } from '../utils/Api';

export default function FileUploadHandler({ Name = "file", form_data, setFormData, error, setError, Section = 'Files' }) {
    const [preview, setPreview] = useState(null);
    useEffect(() => {
        const fetchURL = async () => {
            const URL = await getURL();
            console.log("Test: ", `${URL}/${(form_data[Name] || null)}`);
            // Update the preview when form_data changes
            setPreview(`${URL}/${(form_data[Name] || null)}`);
            // setFormData({...form_data})
        };
        if (form_data[Name]) {
            fetchURL(); // Call the async function
        }
    }, [form_data, Name]);

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('baseDir', Section);
            formData.append('filename', 'file');
            formData.append('original_file', file);
            const data = await postAPIForm('/file/uploadFile', formData);
            if (data.status === 0) {
                setError({ status: 0, msg: 'Image Not Uploaded' });
            } else {
                const filePath = data.filepath;
                setPreview(filePath);
                setFormData(prev => ({ ...prev, [Name]: filePath }));
            }
        }
    };

//   console.log("form_data1", form_data);
    return (
        <>
            <div className="form-group mt-3">
                <label htmlFor={Name}>Upload</label>
                <input type="file" className="form-control file" id={Name} onChange={handleImageChange} />
            </div>
            {/* {form_data[Name]} */}
            {preview && (
                <div className="view-image">
                    <div className="close-icon" onClick={() => setPreview(null)}>x</div>
                    <img src={`${preview}`} width={100} height={100} alt="Image Preview" />
                </div>
            )}
        </>
    );
}
