import React from 'react'
import Sidebar from '../Sidebar'
import Header from '../Header'
import Footer from '../Footer'

export default function Index({ children }) {
    return (
        <>
            <Sidebar />
            <div className="wrapper d-flex flex-column min-vh-100">
                <Header />
                <div className="body flex-grow-1">
                    <div className="container-fluid">
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
