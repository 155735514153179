import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import InputHandler from '../../../../UIComponents/InputHandler'
import SelectBoxHandler from '../../../../UIComponents/SelectBoxHandler'
import CheckBoxHandler from '../../../../UIComponents/CheckBoxHandler'
import RadioButtonHandler from '../../../../UIComponents/RadioButtonHandler'
import TextareaHandler from '../../../../UIComponents/TextareaHandler'
import FileUploadHandler from '../../../../UIComponents/FileUploadHandler'
import FormSubmissionHandler from '../../../../UIComponents/FormSubmissionHandler'
import { postAPI } from '../../../../utils/Api';
import { store } from '../../../../app/store'


export default function Form() {
  const user = store.getState().auth.user;
  const { id } = useParams();
  const InitialFormData = {
    id: id,
    is_menu: "NO"
  }
  const [form_data, setFormData] = useState(InitialFormData);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});
  const [SectionList, setSectionList] = useState({});
  const [MenuList, setMenuList] = useState({});


  // Radio Button
  const IsMenuOptions = [{
    label: 'YES',
    value: 'YES'
  }, {
    label: 'NO',
    value: 'NO'
  }]

  useEffect(() => {
    fetchMenu();
  }, [])

  const fetchMenu = async () => {
    try {
      const MenuList = await postAPI('/Menu/List', form_data);
      const TempSection = []
      console.log("MenuList: ", MenuList)
      MenuList?.data?.map((item, i) => !item?.is_menu ? (
        TempSection.push({
          label: item?.name,
          value: item?.id
        })
      ) : '')
      setSectionList(TempSection)
      const TempMenu = []
      MenuList?.data?.map((item, i) => item?.is_menu && !item?.parent_menu ? (
        TempMenu.push({
          label: item?.name,
          value: item?.id
        })
      ) : '')
      setMenuList(TempMenu)
    } catch (error) {
      console.error("Error fetching class and section lists: ", error);
    }
  };



  useEffect(async () => {
    if(id) {
      const data = await postAPI('/Menu/fetchSingle', {id: id, org_id: user?.org_id});
      const MenuData = data?.data[0];
      setFormData({...MenuData, id: id})
    }
  }, [user])


  const handleSubmit = async e => {
    e.preventDefault()
    if(id) {
      const data = await postAPI('/Menu/update', form_data);
    } else {
      const data = await postAPI('/Menu/create', form_data);
    }
  }

  return (
    <>
      <div className='container-fluid'>
        <div className="card mb-3">
          <div className="card-body">
            <FormSubmissionHandler handleSubmit={handleSubmit} editing={editing} error={error}>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <SelectBoxHandler Label="Parent Section" Options={SectionList} Name={"parent_section"} setFormData={setFormData} form_data={form_data} MultiSelection={false}></SelectBoxHandler>
                  </div>
                  <div className='col'>
                    <SelectBoxHandler Label="Parent Menu" Options={MenuList} Name={"parent_menu"} setFormData={setFormData} form_data={form_data} MultiSelection={false}></SelectBoxHandler>
                  </div>
                </div>
              </div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="name" Type="text" Label="Menu Name"></InputHandler>
                  </div>
                  <div className='col'>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="link" Type="text" Label="Menu Link"></InputHandler>
                  </div>
                </div>
              </div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <RadioButtonHandler Label="Is Menu ?" Options={IsMenuOptions} Name={"is_menu"} setFormData={setFormData} form_data={form_data}></RadioButtonHandler>
                  </div>
                  {/* <div className='col'>
                    <FileUploadHandler Name="file" form_data={form_data} setFormData={setFormData} error={error} setError={setError} Section='Announcement'></FileUploadHandler>
                  </div> */}
                </div>
              </div>
            </FormSubmissionHandler>
          </div>
        </div>
      </div>
    </>
  )
}
