// src/app/globalSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gProps: {},
  // Add other global state properties as needed
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalProps: (state, action) => {
      state.gProps = action.payload;
    },
    // Add other reducers to update the state as needed
  },
});

export const { setGlobalProps } = globalSlice.actions;
export default globalSlice.reducer;
