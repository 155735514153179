import React from 'react'

export default function Footer() {
    return (
        <footer className="footer">
            <div>
                <a href="https://coreui.io">CoreUI </a>
                <a href="https://coreui.io/product/free-bootstrap-admin-template/">Bootstrap Admin Template</a> &copy; 2024 creativeLabs.
            </div>
            <div className="ms-auto">
                Powered by&nbsp;<a href="https://coreui.io/docs/">CoreUI UI Components</a>
            </div>
        </footer>
    )
}
