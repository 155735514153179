import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import InputHandler from '../../../../UIComponents/InputHandler'
import SelectBoxHandler from '../../../../UIComponents/SelectBoxHandler'
import CheckBoxHandler from '../../../../UIComponents/CheckBoxHandler'
import RadioButtonHandler from '../../../../UIComponents/RadioButtonHandler'
import TextareaHandler from '../../../../UIComponents/TextareaHandler'
import FileUploadHandler from '../../../../UIComponents/FileUploadHandler'
import FormSubmissionHandler from '../../../../UIComponents/FormSubmissionHandler'
import { postAPI } from '../../../../utils/Api';
import { store } from '../../../../app/store'


export default function Form() {
  const user = store.getState().auth.user;
  const { id } = useParams();
  const InitialFormData = {
    id: id, 
    org_id: user?.org_id,
    lead_from: "GOOGLE",
  }
  const [form_data, setFormData] = useState(InitialFormData);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});


  // Radio Button
  const LeadFormOptions = [{
    label: 'GOOGLE',
    value: 'GOOGLE'
  }, {
    label: 'FACEBOOK',
    value: 'FACEBOOK'
  }, {
    label: 'INSTAGRAM',
    value: 'INSTAGRAM'
  }, {
    label: 'TWITTER',
    value: 'TWITTER'
  }, {
    label: 'YOUTUBE',
    value: 'YOUTUBE'
  }, {
    label: 'LINKEDIN',
    value: 'LINKEDIN'
  }, {
    label: 'WHATSAPP',
    value: 'WHATSAPP'
  }, {
    label: 'ADVERTISE',
    value: 'ADVERTISE'
  }, {
    label: 'OTHER',
    value: 'OTHER'
  }]



  useEffect(async () => {
    if(id) {
      const data = await postAPI('/Leads/fetchSingle', {id: id, org_id: user?.org_id});
      const LeadsData = data?.data[0];
      setFormData({...LeadsData, id: id})
    }
  }, [user])


  const handleSubmit = async e => {
    e.preventDefault()
    if(id) {
      const data = await postAPI('/Leads/update', form_data);
    } else {
      const data = await postAPI('/Leads/create', form_data);
    }
  }

  return (
    <>
      <div className='container-fluid'>
        <div className="card mb-3">
          <div className="card-body">
            <FormSubmissionHandler handleSubmit={handleSubmit} editing={editing} error={error}>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <FileUploadHandler Name="file" form_data={form_data} setFormData={setFormData} error={error} setError={setError} Section='Announcement'></FileUploadHandler>
                  </div>
                </div>
              </div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="name" Type="text" Label="Name"></InputHandler>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="email" Type="text" Label="Email Id"></InputHandler>
                  </div>
                  <div className='col'>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="contact_number" Type="text" Label="Contact Number"></InputHandler>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="address" Type="text" Label="Address"></InputHandler>
                  </div>
                </div>
              </div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <RadioButtonHandler Label="Lead From" Options={LeadFormOptions} Name={"lead_from"} setFormData={setFormData} form_data={form_data}></RadioButtonHandler>
                  </div>
                </div>
              </div>
            </FormSubmissionHandler>
          </div>
        </div>
      </div>
    </>
  )
}
