import React, { useEffect, useState } from 'react'

export default function RadioButtonHandler({ Label = "", Options = [], Name = "radio", setFormData, form_data }) {
  // useEffect(() => {
  //   if (form_data[Name]) {
  //     setFormData({...form_data})
  //   }
  // }, [form_data, Name])
  return (
    <div className='form-group mt-3'>
      <label className="form-label">{Label}</label>
      <div className='d-flex'>
        {Options && Options?.length ?
          Options?.map((element, index) => (
            <div className="form-check me-3">
              <input id={`${element.label}-${index}`} className="form-check-input" type="radio" name={Name} checked={form_data[Name] === element.value} value={element.value} onClick={(e) => setFormData({ ...form_data, [Name]: e.target.value })} />
              <label className="form-check-label" htmlFor={`${element.label}-${index}`}>
                {element.label}
              </label>
            </div>
          ))
          : ''}
      </div>
    </div>
  )
}
