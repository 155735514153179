import React from 'react'
import Header from '../Universal/Header'

export default function Index() {
  return (
    <>
      <Header />
      <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="clearfix">
                <h1 className="float-start display-3 me-4">500</h1>
                <h4 className="pt-3">Houston, we have a problem!</h4>
                <p className="text-body-secondary">The page you are looking for is temporarily unavailable.</p>
              </div>
              <div className="input-group">
                <span className="input-group-text">
                  {/* <svg className="icon">
                  <use xlink:href="node_modules/@coreui/icons/sprites/free.svg#cil-magnifying-glass"></use>
                </svg> */}
                </span>
                <input className="form-control" id="prependedInput" size="16" type="text" placeholder="What are you looking for?" />
                <button className="btn btn-info" type="button">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
