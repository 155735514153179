// src/features/api/apiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

//const API_URL = 'https://api.example.com/auth';
const API_URL = process.env.REACT_APP_NODE_ENV ==='development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;


// Async thunk to fetch data from a dynamic API endpoint with token from the store
export const fetchData = createAsyncThunk('api/fetchData', async ({ apiEndpoint, method = 'GET', data }, { getState }) => {
    const state = getState();
    const token = state.auth.token; // Adjust this based on where your token is stored in the state

    const config = {
        headers: {
            Authorization: `Bearer ${token}`, // Add token to the request headers
        },
    }
    let response;
    if (method === 'POST') {
        response = await axios.post(`${API_URL}${apiEndpoint}`, data, config);
    } else {
        response = await axios.get(`${API_URL}${apiEndpoint}`, config);
    }
    return response.data.data;
});

const apiSlice = createSlice({
    name: 'api',
    initialState: {
        data: [],
        loading: true,
        error: null,
    },
    reducers: {
        updateAnswer: (state, action) => {
            const { index, answer } = action.payload;
            if (state.data[index]) {
                state.data[index].answer = answer;
            }
        },
        fetchSuccess: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});
export const { updateAnswer, fetchSuccess } = apiSlice.actions;
export default apiSlice.reducer;

