import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import InputHandler from '../../../../UIComponents/InputHandler'
import SelectBoxHandler from '../../../../UIComponents/SelectBoxHandler'
import CheckBoxHandler from '../../../../UIComponents/CheckBoxHandler'
import RadioButtonHandler from '../../../../UIComponents/RadioButtonHandler'
import TextareaHandler from '../../../../UIComponents/TextareaHandler'
import FileUploadHandler from '../../../../UIComponents/FileUploadHandler'
import FormSubmissionHandler from '../../../../UIComponents/FormSubmissionHandler'
import { postAPI } from '../../../../utils/Api';
import { store } from '../../../../app/store'


export default function Form() {
  const user = store.getState().auth.user;
  const { id } = useParams();
  const InitialFormData = {
    id: id, 
    org_id: user?.org_id,
    lead_from: "GOOGLE",
  }
  const [form_data, setFormData] = useState(InitialFormData);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});



  useEffect(async () => {
    if(id) {
      const data = await postAPI('/Notice/fetchSingle', {id: id, org_id: user?.org_id});
      const NoticeData = data?.data[0];
      setFormData({...NoticeData, id: id})
    }
  }, [user])


  const handleSubmit = async e => {
    e.preventDefault()
    if(id) {
      const data = await postAPI('/Notice/update', form_data);
    } else {
      const data = await postAPI('/Notice/create', form_data);
    }
  }

  return (
    <>
      <div className='container-fluid'>
        <div className="card mb-3">
          <div className="card-body">
            <FormSubmissionHandler handleSubmit={handleSubmit} editing={editing} error={error}>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="date" Type="date" Label="Date"></InputHandler>
                  </div>
                  <div className='col'>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="notice_title" Type="text" Label="Notice Title"></InputHandler>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <TextareaHandler form_data={form_data} setFormData={setFormData} Name="notice_details" Type="text" Label="Notice Details"></TextareaHandler>
                  </div>
                </div>
              </div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <FileUploadHandler Name="attachments" form_data={form_data} setFormData={setFormData} error={error} setError={setError} Section='Attachments'></FileUploadHandler>
                  </div>
                </div>
              </div>
            </FormSubmissionHandler>
          </div>
        </div>
      </div>
    </>
  )
}
