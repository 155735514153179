import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import InputHandler from '../../../../UIComponents/InputHandler'
import SelectBoxHandler from '../../../../UIComponents/SelectBoxHandler'
import CheckBoxHandler from '../../../../UIComponents/CheckBoxHandler'
import RadioButtonHandler from '../../../../UIComponents/RadioButtonHandler'
import TextareaHandler from '../../../../UIComponents/TextareaHandler'
import FileUploadHandler from '../../../../UIComponents/FileUploadHandler'
import FormSubmissionHandler from '../../../../UIComponents/FormSubmissionHandler'
import { postAPI } from '../../../../utils/Api';
import { store } from '../../../../app/store'


export default function Form() {
  const user = store.getState().auth.user;
  const { id } = useParams();
  const InitialFormData = {
    id: id,
    org_id: user?.org_id,
  }
  const [form_data, setFormData] = useState(InitialFormData);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});
  const [SectionList, setSectionList] = useState({});
  const [MenuList, setMenuList] = useState({});



  useEffect(async () => {
    if(id) {
      const data = await postAPI('/Sections/fetchSingle', {id: id, org_id: user?.org_id});
      const SectionsData = data?.data[0];
      setFormData({...SectionsData, id: id})
    }
  }, [user])


  const handleSubmit = async e => {
    e.preventDefault()
    if(id) {
      const data = await postAPI('/Sections/update', form_data);
    } else {
      const data = await postAPI('/Sections/create', form_data);
    }
  }

  return (
    <>
      <div className='container-fluid'>
        <div className="card mb-3">
          <div className="card-body">
            <FormSubmissionHandler handleSubmit={handleSubmit} editing={editing} error={error}>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <InputHandler form_data={form_data} setFormData={setFormData} Name="title" Type="text" Label="Title"></InputHandler>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <TextareaHandler form_data={form_data} setFormData={setFormData} Name="contents" Type="text" Label="Details"></TextareaHandler>
                  </div>
                </div>
              </div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <FileUploadHandler Name="images" form_data={form_data} setFormData={setFormData} error={error} setError={setError} Section='Contents'></FileUploadHandler>
                  </div>
                </div>
              </div>
            </FormSubmissionHandler>
          </div>
        </div>
      </div>
    </>
  )
}
