import { createSlice } from '@reduxjs/toolkit';


const authSlice = createSlice({
    name: 'auth',
    initialState: {
      isAuthenticated: false,
      role:0,     
      user: null,
      token: null, // Store the token here
    },
    reducers: {
      login(state, action) {
        console.log(action, action)
        state.isAuthenticated = true;   
        state.username = action.payload.username;
        state.user_unique_id = action.payload.user_unique_id;
        state.org_id = action.payload.org_id;
        state.user = action.payload.user;
        state.role = action.payload.user.role;
        state.token = action.payload.token; // Save the token on login
      },
      logout(state) {
        state.isAuthenticated = false;    
        state.user = null;
        state.token = null;
        state.role = 0
      },
      updateUser(state, action) {
          if (state.user) {
              state.user.is_profile_updated = action.payload.is_profile_updated;
              state.user.class = action.payload.class;
              state.user.section = action.payload.section;
          }
      },
    },
  });
  

export const { login, logout, updateUser} = authSlice.actions;
export default authSlice.reducer;
