import React from 'react'
import { BsToggle2Off, BsToggle2On, BsPen, BsPlusLg, BsEye, BsTrash3 } from "react-icons/bs";
import { postAPI } from '../../../../utils/Api';
import { store } from '../../../../app/store'


const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function List({ Location, TableHeaders, Data, Actions }) {
    const toggleStatus = async (url, id, status) => {
        const filter = {
            id: id,
            status: status
        }
        const data = await postAPI(url, filter);
        console.log(url, id, status)
    }
    const handleRemove = async (url, id) => {
        const filter = {
            id: id,
            is_trash: 1
        }
        const data = await postAPI(url, filter);
        console.log(url, id)
    }
    return (
        <div className="table-responsive">
            <table className="table border mb-0">
                <thead className="fw-semibold text-nowrap">
                    <tr className="align-middle">
                        {
                            TableHeaders && TableHeaders?.length > 0 ?
                                TableHeaders?.map(header => (
                                    <th className="bg-body-secondary">
                                        {header?.name}
                                    </th>
                                ))
                                : ''
                        }
                        <th className="bg-body-secondary" style={{ width: "250px" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Data && Data?.length > 0 ? Data?.map((item, index) => (
                            <tr className="align-middle" key={item?.id}>
                                {
                                    TableHeaders && TableHeaders?.length > 0 ?
                                        TableHeaders?.map(header => (

                                            <td key={header?.id}>
                                                <div className="small text-body-secondary">
                                                    {
                                                        header?.datatype == 'text' ? item[header?.key] :
                                                            header?.datatype == 'image' ?
                                                                <td className="text-center">
                                                                    <div className="avatar avatar-md"><img className="avatar-img" src={`${API_URL}/${item[header?.key]}`} alt={header?.name} /><span className="avatar-status bg-success"></span></div>
                                                                </td> :
                                                                header?.datatype == 'bool' ? item[header?.key] ? 'YES' : 'NO' :
                                                                    header?.datatype == 'file' ? <a href={item[header?.key]} target="_blank">Download</a> :
                                                                        header?.datatype == 'link' ? <a href={item[header?.key]} target="_blank">Visit</a> :
                                                                            item[header?.key]
                                                    }
                                                </div>
                                                {/* <div className="fw-semibold text-nowrap">10 sec ago</div> */}
                                            </td>
                                        ))
                                        : ''
                                }
                                <td>
                                    <div className="d-flex justify-content-between">
                                        {
                                            Actions && Actions?.length > 0 ?
                                                Actions?.map(action => (
                                                    <>
                                                        {
                                                            action?.type == 'TOGGLE' ?
                                                                <div className="small text-body-secondary">
                                                                    {
                                                                        item['status'] == 1 ?
                                                                            <BsToggle2On onClick={() => toggleStatus(`${action?.ajaxUrl}`, item?.id, 0)} />
                                                                            :
                                                                            <BsToggle2Off onClick={() => toggleStatus(`${action?.ajaxUrl}`, item?.id, 1)} />
                                                                    }
                                                                    {/* {action?.name} */}
                                                                </div>
                                                                :
                                                                action?.type == 'BUTTON' ?
                                                                    action?.name == 'View' ?
                                                                        <button class={`btn btn-sm btn-${action?.color}`} onClick={e => window.location.href = `${Location.pathname}${action?.ajaxUrl}${item?.id}`}>
                                                                            <BsEye />
                                                                        </button>
                                                                    :
                                                                    action?.name == 'Edit' ?
                                                                        <button class={`btn btn-sm btn-${action?.color}`} onClick={e => window.location.href = `${Location.pathname}${action?.ajaxUrl}${item?.id}`}>
                                                                            <BsPen />
                                                                        </button>
                                                                    :
                                                                    action?.name == 'Remove' ?
                                                                        <button class={`btn btn-sm btn-${action?.color}`} onClick={() => handleRemove(`${action?.ajaxUrl}`, item?.id)}>
                                                                            <BsTrash3 />
                                                                        </button>
                                                                    :
                                                                    <></>
                                                                : ''
                                                        }
                                                        {/* <div className="fw-semibold text-nowrap">10 sec ago</div> */}
                                                    </>
                                                ))
                                                : ''
                                        }
                                    </div>
                                </td>

                            </tr>
                        )) : ''
                    }
                </tbody>
            </table>
        </div>
    )
}
