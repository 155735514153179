import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import PageTitle from '../Universal/PageTitle'
import Wrapper from '../Universal/Wrapper/Index'
import UpdateForm from './Form'
import List from './List'
import { postAPI } from '../../../../utils/Api';
import { store } from '../../../../app/store'

export default function Index({ Title='Slider', Type="LIST" }) {
  const { id } = useParams();
  const user = store.getState().auth.user;
  const location = useLocation();

  // Initial Data
  const InitialActions = [{
    id: 1,
    name: 'Status',
    type: 'TOGGLE',
    color: 'success',
    ajaxUrl: `/Slider/toggleStatus`,
  }, {
    id: 2,
    name: 'View',
    type: 'BUTTON',
    color: 'primary',
    ajaxUrl: `/View/`,
  }, {
    id: 3,
    name: 'Edit',
    type: 'BUTTON',
    color: 'warning',
    ajaxUrl: `/Edit/`,
  }, {
    id: 4,
    name: 'Remove',
    type: 'BUTTON',
    color: 'danger',
    ajaxUrl: `/Slider/remove`,
  }];
  const InitialTableHeaders = [{
    id: 1,
    key: 'id',
    name: '#',
    datatype: 'id',
  }, {
    id: 2,
    key: 'slider_image',
    name: 'Image',
    datatype: 'image',
  }, {
    id: 3,
    key: 'title',
    name: 'Title',
    datatype: 'text',
  }, {
    id: 4,
    key: 'subtitle',
    name: 'Subtitle',
    datatype: 'text',
  // }, {
  //   id: 5,
  //   key: 'contact_no',
  //   name: 'Contact Number',
  //   datatype: 'text',
  // }, {
  //   id: 6,
  //   key: 'attachment',
  //   name: 'Attachment',
  //   datatype: 'file',
  }];
  const InitialData = [];
  // Initial Data

  const [FormVisibility, setFormVisibility] = useState(id ? true : false);
  const [PageType, setPageType] = useState(id ? 'EDIT' : Type);
  const [Actions, setActions] = useState(InitialActions);
  const [TableHeaders, setTableHeaders] = useState(InitialTableHeaders);
  const [Data, setData] = useState(InitialData);

  useEffect(async () => {
    const filter = {
      org_id: user?.org_id,
    }
    const data = await postAPI('/Slider/list', filter);
    setData(data?.data)
  }, [user])

  const handleChangeVisibility = e => {
    setPageType(!FormVisibility ? 'ADD' : Type)
    setFormVisibility(!FormVisibility);
  }
  return (
    <Wrapper>
      <div className="d-flex justify-content-start mb-3">
        <span>Home &nbsp; </span>
        <span> &gt; {location.pathname.replace("/", '')}</span>
      </div>
      <PageTitle PageType={PageType} Title={Title}>
        <button type="button" className="btn btn-primary mb-3" onClick={handleChangeVisibility}>
        {
        !FormVisibility ? 'ADD' : `Back To ${Title}` 
        }
        </button>
      </PageTitle>
      {
        FormVisibility ?
          <UpdateForm />
          :
          <List Location={location} TableHeaders={TableHeaders} Data={Data} Actions={Actions} />
      }
    </Wrapper>
  )
}
