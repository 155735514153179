import React, { useEffect, useState } from 'react'

export default function SelectBoxHandler({ Label = "", Options = [], Name = "select", setFormData, form_data, MultiSelection=false }) {
  useEffect(() => {
    if (form_data[Name]) {
      setFormData({...form_data})
    }
  }, [form_data, Name])
  const handleSingleSelect = e => {
    const {options} = e.target;
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        setFormData({ ...form_data, [Name]: options[i].value })
      }
    }
  }
  const handleMultiSelect = e => {
    const {options} = e.target;
    const selectedValues = [];
    
    // Iterate over the options and collect the ones that are selected
    for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
            selectedValues.push(options[i].value);
        }
    }
    setFormData({ ...form_data, [Name]: selectedValues })
  }
  return (
    <div className='form-group mt-3'>
      <label className="form-label">{Label}</label>
      <select
          className='form-select'
          value={form_data[Name]}
          name={Name}
          multiple={MultiSelection}
          onChange={MultiSelection?handleMultiSelect:handleSingleSelect}
        >
          <option value="">Select {Label}</option>
          {Options && Options?.length ?
          Options?.map(element => (
            <option value={element.value}>{element.label}</option>
          ))
          : ''}
      </select>
    </div>
  )
}
