import React from 'react'
import Sidebar from '../Sidebar'
import Header from '../Header'
import Footer from '../Footer'

export default function Index({ children }) {
    return (
        <>
            <Sidebar />
            <Header />
            <div className="body flex-grow-1">
                <div className="container-fluid">
                    {children}
                </div>
            </div>
            <Footer />
        </>
    )
}
