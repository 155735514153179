import React, { useEffect, useState } from 'react'

export default function InputHandler({form_data, setFormData, Name="input", Type="text", Label=""}) {
  // useEffect(() => {
  //   if (form_data[Name]) {
  //     setFormData({...form_data})
  //   }
  // }, [form_data, Name])
  return (
    <div className='mt-3 form-group'>
        <label className="form-label">{Label}</label>
        <input 
        type={Type} 
        placeholder={`Enter ${Label}`} 
        className="form-control mb-3" 
        value={form_data[Name]}
        onChange={(e) => setFormData({...form_data, [Name]:e.target.value})}
        />
    </div>
  )
}
