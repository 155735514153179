import React from 'react'
import Wrapper from '../Universal/Wrapper/Index'

export default function Index() {
  return (
    <Wrapper>
      Dashboard
    </Wrapper>
  )
}
