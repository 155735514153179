import axios from 'axios';
import { store } from '../app/store';
//const API_URL = 'https://api.example.com/auth';
//const API_URL = 'http://localhost:9001';
import JSEncrypt from 'jsencrypt';
import { parseISO, format } from 'date-fns';
import { logout } from '../auth/authSlice';
const API_URL = process.env.REACT_APP_NODE_ENV ==='development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;


export const apiAxios = axios.create({
  baseURL: API_URL
});


export const setupInterceptors = (store) => {
  apiAxios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // Token is invalid/expired
        store.dispatch(logout());
        window.location.href = '/login'; // Redirect to login
      }
      return Promise.reject(error);
    }
  );
};

const getToken = async () => {
  const state = store.getState();
  return state.auth.token; // Get the token from the Redux store
};

const getConfig = async () => {
  const token = await getToken();
  return {    
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };
};
const getConfigForm = async () => {
  const token = await getToken();
  return {    
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    }
  };
};


export const loginApi = async (credentials) => {
  const response = await apiAxios.post(`${API_URL}/signin`, credentials);
  return response.data;
};
export const signup = async (data) => {
    const response = await apiAxios.post(`${API_URL}/signup`, data);
    return response.data;
  };


export const postAPI = async (apiName,data) => {    
  const response = await apiAxios.post(`${API_URL}${apiName}`, data, await getConfig());
  return response.data;
};
export const postAPIForm = async (apiName,data) => {    
  const response = await apiAxios.post(`${API_URL}${apiName}`, data, await getConfigForm());
  return response.data;
};
export const customPostAPIForm = async (apiName,data) => {    
  const response = await apiAxios.post(`${apiName}`, data, await getConfigForm());
  return response.data;
};
export const putAPIForm = async (apiName,data) => {    
  const response = await apiAxios.put(`${API_URL}${apiName}`, data, await getConfigForm());
  return response.data;
};
export const getAPI = async (apiName) => {    
  const response = await apiAxios.get(`${API_URL}${apiName}`, await getConfig());
  return response.data;
};

export const putAPI = async (apiName,data) => {    
  const response = await apiAxios.put(`${API_URL}${apiName}`, data, await getConfig());
  return response.data;
};
export const deleteAPI = async (apiName) => {    
  const response = await apiAxios.delete(`${API_URL}${apiName}`, await getConfig());
  return response.data;
}
export const getTeacherList = async () => {    
  const response = await apiAxios.post(`${API_URL}/teachers`, {}, await getConfig());
  return response.data;
};


export const getClassList = async (data) => {    
  const for_classes = [];
  for (let i = 1; i <= 12; i++) {
    for_classes.push({ value: i, label: i });
  }

  return for_classes
};

export const getSubjects = async () => {
  const subjects = [
    'Mathematics',
    'Science',
    'History',
    'English',
    'Computer Science',
    'Geography',
    'Art',
    'Music',
    'Physical Education',
    'Other',
  ];
  return subjects
}


export const getSectionsList = async (data) => {    
  
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const sections=[]
  for (let i of alphabet)
  {
    sections.push({ value: i, label: i });
  }
  return sections
};
export const getBreaks = async (data) => {    
  
  const alphabet = [2,3,4];
  const sections=[]
  for (let i of alphabet)
  {
    sections.push({ value: i, label: i });
  }
  return sections
};
export const encryptId = async (id) => {
  try {
    // Fetch public key from server
    const response = await apiAxios.get('/p-k',await getConfig()); // Replace with your API endpoint to fetch public key
    const publicKey = response.data.publicKey; // Assuming the server provides the public key

    // Encrypt with public key (using RSA)
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    const encrypted = encrypt.encrypt(id.toString());

    return encrypted;
  } catch (error) {
    console.error('Error encrypting ID:', error);
    return ''
  }
};

export const getURL=()=>{
  return process.env.REACT_APP_NODE_ENV ==='development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

}
// Similarly, other authentication-related API calls


export const updateProfile= async () => {    
  const response = await apiAxios.put(`${API_URL}/profile`, {}, await getConfig());
  return response.data;
};

export const formatDate = (dateString) => {
  const date = parseISO(dateString);
  const day = format(date, 'd');
  const month = format(date, 'MMM');
  const year = format(date, 'yyyy');

  return { day,month, year}
};