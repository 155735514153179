import React from 'react'

export default function Header() {
    return (
        <>
            {/* <div className="sidebar sidebar-dark sidebar-fixed border-end" id="sidebar">
                <div className="sidebar-header border-bottom">
                    <div className="sidebar-brand"></div>
                    <button className="btn-close d-lg-none" type="button" data-coreui-dismiss="offcanvas" data-coreui-theme="dark" aria-label="Close" onclick='coreui.Sidebar.getInstance(document.querySelector("#sidebar")).toggle()'></button>
                </div>
                <ul className="sidebar-nav" data-coreui="navigation" data-simplebar>
                    <li className="nav-item">
                        <a className="nav-link" href="index.html">
                            Dashboard<span className="badge badge-sm bg-info ms-auto">NEW</span>
                        </a>
                    </li>
                    <li className="nav-title">Theme</li>
                    <li className="nav-item">
                        <a className="nav-link" href="colors.html">
                            Colors
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="typography.html">
                            Typography
                        </a>
                    </li>
                    <li className="nav-title">Components</li>
                    <li className="nav-group">
                        <a className="nav-link nav-group-toggle" href="#">
                            Base
                        </a>
                        <ul className="nav-group-items compact">
                            <li className="nav-item">
                                <a className="nav-link" href="base/accordion.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Accordion
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/breadcrumb.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Breadcrumb
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/cards.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Cards
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/carousel.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Carousel
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/collapse.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Collapse
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/list-group.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    List group
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/navs-tabs.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Navs &amp; Tabs
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/pagination.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Pagination
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/placeholders.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Placeholders
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/popovers.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Popovers
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/progress.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Progress
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/spinners.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Spinners
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/tables.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Tables
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="base/tooltips.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Tooltips
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-group">
                        <a className="nav-link nav-group-toggle" href="#">
                            Buttons
                        </a>
                        <ul className="nav-group-items compact">
                            <li className="nav-item">
                                <a className="nav-link" href="buttons/buttons.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Buttons
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="buttons/button-group.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Buttons Group
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="buttons/dropdowns.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Dropdowns
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="charts.html">
                            Charts
                        </a>
                    </li>
                    <li className="nav-group">
                        <a className="nav-link nav-group-toggle" href="#">
                            Forms
                        </a>
                        <ul className="nav-group-items compact">
                            <li className="nav-item">
                                <a className="nav-link" href="forms/form-control.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Form Control
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="forms/select.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Select
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="forms/checks-radios.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Checks and radios
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="forms/range.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Range
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="forms/input-group.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Input group
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="forms/floating-labels.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Floating labels
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="forms/layout.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Layout
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="forms/validation.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Validation
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-group">
                        <a className="nav-link nav-group-toggle" href="#">
                            Icons
                        </a>
                        <ul className="nav-group-items compact">
                            <li className="nav-item">
                                <a className="nav-link" href="icons/coreui-icons-free.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    CoreUI Icons<span className="badge badge-sm bg-success ms-auto">Free</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="icons/coreui-icons-brand.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    CoreUI Icons - Brand
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="icons/coreui-icons-flag.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    CoreUI Icons - Flag
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-group">
                        <a className="nav-link nav-group-toggle" href="#">
                            Notifications
                        </a>
                        <ul className="nav-group-items compact">
                            <li className="nav-item">
                                <a className="nav-link" href="notifications/alerts.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Alerts
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="notifications/badge.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Badge
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="notifications/modals.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Modals
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="notifications/toasts.html">
                                    <span className="nav-icon">
                                        <span className="nav-icon-bullet"></span>
                                    </span>{" "}
                                    Toasts
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="widgets.html">
                            Widgets<span className="badge badge-sm bg-info ms-auto">NEW</span>
                        </a>
                    </li>
                    <li className="nav-divider"></li>
                    <li className="nav-title">Extras</li>
                    <li className="nav-group">
                        <a className="nav-link nav-group-toggle" href="#">
                            Pages
                        </a>
                        <ul className="nav-group-items compact">
                            <li className="nav-item">
                                <a className="nav-link" href="login.html" target="_top">
                                    Login
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="register.html" target="_top">
                                    Register
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="404.html" target="_top">
                                    Error 404
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="500.html" target="_top">
                                    Error 500
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item mt-auto">
                        <a className="nav-link" href="https://coreui.io/docs/templates/installation/" target="_blank">
                            Docs
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-primary fw-semibold" href="https://coreui.io/product/bootstrap-dashboard-template/" target="_top">
                            Try CoreUI PRO
                        </a>
                    </li>
                </ul>
                <div className="sidebar-footer border-top d-none d-md-flex">
                    <button className="sidebar-toggler" type="button" data-coreui-toggle="unfoldable"></button>
                </div>
            </div> */}
        </>
    )
}
