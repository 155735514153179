// import React, { useEffect, useState } from 'react'
// // import { CKEditor } from '@ckeditor/ckeditor5-react';
// // import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// // import { HtmlEmbed } from '@ckeditor/ckeditor5-html-embed';
// // import { Essentials } from '@ckeditor/ckeditor5-essentials';
// // import { DecoupledEditor, Essentials, Paragraph } from 'ckeditor5';
// import { Strikethrough, Subscript, Superscript, FontFamily, FontSize, FontColor, FontBackgroundColor, SourceEditing, CodeBlock, HtmlEmbed, ClassicEditor, Context, ContextWatchdog, TextTransformation, CKFinderUploadAdapter, Paragraph, Essentials, Heading, Autoformat, Bold, Italic, BlockQuote, Image, ImageCaption, ImageStyle, ImageToolbar, ImageUpload, CloudServices, CKBox, CKFinder, EasyImage, List, Indent, Link, MediaEmbed, PasteFromOffice, Table, TableToolbar, PictureEditing } from 'ckeditor5';
// import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';

// import 'ckeditor5/ckeditor5.css';

// export default function TextareaHandler({ form_data, setFormData, Name = 'textarea', ClassName = 'custom-editor', Label="Textarea" }) {
//     const [content, setContent] = useState({})
//     // console.log("content", content, form_data)
//     const handleContentChange = (data) => {
//         console.log("-----------------------")
//         console.log("content", content, form_data)
//         // setFormData({...content, [Name]: data})
//         console.log("-----------------------")
//     }
//     useEffect(() => {
//         if(form_data[Name])
//             setContent(form_data)
//     }, [form_data])
//     return (
//         <div className={ClassName}>
//             <label className="form-label">{Label}</label>
//             <CKEditor
//                 editor={ClassicEditor}
//                 // config={{
//                 //     plugins: [ HtmlEmbed ],
//                 //     toolbar: ['htmlEmbed']
//                 // }}
//                 config={{
//                     plugins: [ SourceEditing, CodeBlock, TextTransformation, CKFinderUploadAdapter, Paragraph, Essentials, Heading, Autoformat, Bold, Italic, Strikethrough, Subscript, Superscript, FontFamily, FontSize, FontColor, FontBackgroundColor, BlockQuote, Image, ImageCaption, ImageStyle, ImageToolbar, ImageUpload, CloudServices, CKBox, CKFinder, EasyImage, List, Indent, Link, MediaEmbed, PasteFromOffice, Table, TableToolbar, PictureEditing, HtmlEmbed ],
//                     toolbar: [ 'undo', 'redo', '|', 'heading', '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|', 'blockQuote', 'insertTable', 'imageUpload', 'mediaEmbed', 'link', '|', 'htmlEmbed', 'codeBlock', 'SourceEditing', '|', 'strikethrough', 'subscript', 'superscript', 'code', 'numberedList', 'bulletedList', 'todoList', 'outdent', 'indent' ],
//                     image: {
//                         toolbar: [
//                             'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'
//                         ],
//                     },
//                     table: {
//                         contentToolbar: [
//                             'tableColumn', 'tableRow', 'mergeTableCells'
//                         ],
//                     },
//                 }}
//                 data={form_data[Name]}
//                 onReady={editor => {
//                     console.log('Editor is ready to use!', editor);
//                 }}
//                 onChange={(event, editor) => handleContentChange(editor.getData())}
//                 onBlur={(event, editor) => {
//                     console.log('Blur.', editor);
//                 }}
//                 onFocus={(event, editor) => {
//                     console.log('Focus.', editor);
//                 }}
//             />
//         </div>
//     );
// }



// import React, { useEffect, useState } from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import {
//     Strikethrough, Subscript, Superscript, FontFamily, FontSize, FontColor, FontBackgroundColor,
//     SourceEditing, CodeBlock, HtmlEmbed, TextTransformation, CKFinderUploadAdapter, Paragraph,
//     Essentials, Heading, Autoformat, Bold, Italic, BlockQuote, Image, ImageCaption, ImageStyle,
//     ImageToolbar, ImageUpload, CloudServices, CKBox, CKFinder, EasyImage, List, Indent, Link,
//     MediaEmbed, PasteFromOffice, Table, TableToolbar, PictureEditing
// } from 'ckeditor5';

// import 'ckeditor5/ckeditor5.css';

import React, { useEffect, useState } from 'react'
import { Strikethrough, Subscript, Superscript, FontFamily, FontSize, FontColor, FontBackgroundColor, SourceEditing, CodeBlock, HtmlEmbed, ClassicEditor, Context, ContextWatchdog, TextTransformation, CKFinderUploadAdapter, Paragraph, Essentials, Heading, Autoformat, Bold, Italic, BlockQuote, Image, ImageCaption, ImageStyle, ImageToolbar, ImageUpload, CloudServices, CKBox, CKFinder, EasyImage, List, Indent, Link, MediaEmbed, PasteFromOffice, Table, TableToolbar, PictureEditing } from 'ckeditor5';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';

import 'ckeditor5/ckeditor5.css';

export default function TextareaHandler({ form_data, setFormData, Name = 'textarea', ClassName = 'custom-editor', Label = "Textarea" }) {
    const [content, setContent] = useState(form_data[Name] || ""); // Initialize from form_data

    // Update the form data when content changes
    const handleContentChange = (data) => {
        setContent(data);
        setFormData(prevData => ({ ...prevData, [Name]: data })); // Update form_data with new content
    };

    // Sync content with form_data whenever form_data updates
    useEffect(() => {
        if (form_data[Name] !== content) {
            setContent(form_data[Name] || ""); // Update content state if form_data changes
        }
    }, [form_data, Name, content]);

    return (
        <div className={ClassName}>
            <label className="form-label">{Label}</label>
            <CKEditor
                editor={ClassicEditor}
                config={{
                    plugins: [
                        SourceEditing, CodeBlock, TextTransformation, CKFinderUploadAdapter, Paragraph, Essentials, Heading, Autoformat,
                        Bold, Italic, Strikethrough, Subscript, Superscript, FontFamily, FontSize, FontColor, FontBackgroundColor,
                        BlockQuote, Image, ImageCaption, ImageStyle, ImageToolbar, ImageUpload, CloudServices, CKBox, CKFinder,
                        EasyImage, List, Indent, Link, MediaEmbed, PasteFromOffice, Table, TableToolbar, PictureEditing, HtmlEmbed
                    ],
                    toolbar: [
                        'undo', 'redo', '|', 'heading', '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', '|',
                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|', 'blockQuote', 'insertTable', 'imageUpload',
                        'mediaEmbed', 'link', '|', 'htmlEmbed', 'codeBlock', 'SourceEditing', '|', 'strikethrough', 'subscript',
                        'superscript', 'code', 'numberedList', 'bulletedList', 'todoList', 'outdent', 'indent'
                    ],
                    image: {
                        toolbar: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
                    },
                    table: {
                        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
                    },
                }}
                data={content} // Set initial content
                onChange={(event, editor) => handleContentChange(editor.getData())}
                onReady={editor => console.log('Editor is ready to use!', editor)}
                onBlur={(event, editor) => console.log('Blur.', editor)}
                onFocus={(event, editor) => console.log('Focus.', editor)}
            />
        </div>
    );
}
