import React from 'react'

export default function PageTitle({ children, PageType = "LIST", Title }) {
  return (
    <div className='cpanel-page-title'>
      <div className="d-flex justify-content-between">
        <h4>{PageType == 'ADD' ? 'Add New' : PageType == 'EDIT' ? 'Update' : ''} {Title}</h4>
        {children}
      </div>
    </div>
  )
}
