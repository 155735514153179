import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes,Redirect, Navigate, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import logo from './logo.svg';
// import './css/style.css';
import './App.css';

// import { store } from './app/store';
import PrivateRoute from './routes/PrivateRoute';


import { useDispatch } from 'react-redux';
import { setupInterceptors } from './utils/Api';

import E404 from './pages/Website/ClientPanel/E404/Index'
import E500 from './pages/Website/ClientPanel/E500/Index'

// Client Panel
import Home from './pages/Website/ClientPanel/Home/Index'
import OTP from './pages/Website/ControlPanel/OTP/Index'
import ForgotPassword from './pages/Website/ControlPanel/ForgotPassword/Index'
import Login from './pages/Website/ControlPanel/Login/Index'
import Register from './pages/Website/ControlPanel/Register/Index'

// Control Panel
import Dashboard from './pages/Website/ControlPanel/Dashboard/Index'

import OrganizationSetup from './pages/Website/ControlPanel/OrganizationSetup/Index'
import EmailTemplateSetup from './pages/Website/ControlPanel/EmailTemplateSetup/Index'
import EmailSetup from './pages/Website/ControlPanel/EmailSetup/Index'
import MenuSetup from './pages/Website/ControlPanel/MenuSetup/Index'
import SliderSetup from './pages/Website/ControlPanel/SliderSetup/Index'
import NoticeSetup from './pages/Website/ControlPanel/NoticeSetup/Index'
import EventSetup from './pages/Website/ControlPanel/EventSetup/Index'
import GallerySetup from './pages/Website/ControlPanel/GallerySetup/Index'
import ManageFAQs from './pages/Website/ControlPanel/ManageFAQs/Index'
import ManageLeads from './pages/Website/ControlPanel/ManageLeads/Index'
import ManageQueries from './pages/Website/ControlPanel/ManageQueries/Index'
import StaffSetup from './pages/Website/ControlPanel/StaffSetup/Index'
import ContentSetup from './pages/Website/ControlPanel/ContentSetup/Index'
import SectionSetup from './pages/Website/ControlPanel/SectionSetup/Index'
import AdditionalPageSetup from './pages/Website/ControlPanel/AdditionalPageSetup/Index'
import UITemplateSetup from './pages/Website/ControlPanel/UITemplateSetup/Index'
import PaymentSetup from './pages/Website/ControlPanel/PaymentSetup/Index'
import PaymentGatewaySetup from './pages/Website/ControlPanel/PaymentGatewaySetup/Index'
import SMSSetup from './pages/Website/ControlPanel/SMSSetup/Index'
import Store from './pages/Website/ControlPanel/Store/Index'






function App() {
	// const user = store.getState().auth.user; 

  return (
    <Router>
      <Routes>
        // Client Panel
        <Route path="/" element={<Home/>} />
        <Route path="/Home" element={<Home/>} />
        // Control Panel
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/OrganizationSetup" element={<OrganizationSetup />} />
        <Route path="/EmailTemplateSetup" element={<EmailTemplateSetup />} />
        <Route path="/EmailTemplateSetup/Edit/:id" element={<EmailTemplateSetup />} />
        <Route path="/EmailSetup" element={<EmailSetup />} />
        <Route path="/EmailSetup/Edit/:id" element={<EmailSetup />} />
        <Route path="/MenuSetup" element={<MenuSetup />} />
        <Route path="/MenuSetup/Edit/:id" element={<MenuSetup />} />
        <Route path="/SliderSetup" element={<SliderSetup />} />
        <Route path="/SliderSetup/Edit/:id" element={<SliderSetup />} />
        <Route path="/NoticeSetup" element={<NoticeSetup />} />
        <Route path="/NoticeSetup/Edit/:id" element={<NoticeSetup />} />
        <Route path="/EventSetup" element={<EventSetup />} />
        <Route path="/EventSetup/Edit/:id" element={<EventSetup/>} />
        <Route path="/GallerySetup" element={<GallerySetup />} />
        <Route path="/GallerySetup/Edit/:id" element={<GallerySetup />} />
        <Route path="/ManageFAQs" element={<ManageFAQs />} />
        <Route path="/ManageFAQs/Edit/:id" element={<ManageFAQs />} />
        <Route path="/ManageLeads" element={<ManageLeads />} />
        {/* <Route path="/ManageLeads" element={<ManageLeads />} /> */}
        <Route path="/ManageQueries" element={<ManageQueries />} />
        {/* <Route path="/ManageQueries" element={<ManageQueries />} /> */}
        <Route path="/StaffSetup" element={<StaffSetup />} />
        <Route path="/StaffSetup/Edit/:id" element={<StaffSetup />} />
        <Route path="/ContentSetup" element={<ContentSetup />} />
        <Route path="/ContentSetup/Edit/:id" element={<ContentSetup />} />
        <Route path="/SectionSetup" element={<SectionSetup />} />
        <Route path="/SectionSetup/Edit/:id" element={<SectionSetup />} />
        <Route path="/AdditionalPageSetup" element={<AdditionalPageSetup />} />
        <Route path="/AdditionalPageSetup/Edit/:id" element={<AdditionalPageSetup />} />
        <Route path="/UITemplateSetup" element={<UITemplateSetup />} />
        <Route path="/UITemplateSetup/Edit/:id" element={<UITemplateSetup />} />
        <Route path="/PaymentSetup" element={<PaymentSetup/>} />
        <Route path="/PaymentSetup/Edit/:id" element={<PaymentSetup/>} />
        <Route path="/PaymentGatewaySetup" element={<PaymentGatewaySetup/>} />
        <Route path="/PaymentGatewaySetup/Edit/:id" element={<PaymentGatewaySetup/>} />
        <Route path="/SMSSetup" element={<SMSSetup/>} />
        <Route path="/SMSSetup/Edit/:id" element={<SMSSetup/>} />
        <Route path="/Store" element={<Store/>} />
        <Route path="/Store/Edit/:id" element={<Store/>} />
        <Route path="/OTP" element={<OTP/>} />
        <Route path="/ForgotPassword" element={<ForgotPassword/>} />
        <Route path="/Login" element={<Login/>} />
        <Route path="/Register" element={<Register/>} />

        <Route path="/404" element={<E404/>} />
        <Route path="/500" element={<E500/>} />
      </Routes>
    </Router>
  );
}
const LoginWrapper = ({ loginProps }) => {
  return <Login {...loginProps} />;
};

export default App;
