import React from 'react'
const API_URL = process.env.REACT_APP_NODE_ENV ==='development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function List({ Location, TableHeaders, Data, Actions }) {
    return (
        <div className="table-responsive">
            <table className="table border mb-0">
                <thead className="fw-semibold text-nowrap">
                    <tr className="align-middle">
                        {
                            TableHeaders && TableHeaders?.length > 0 ?
                                TableHeaders?.map(header => (
                                    <th className="bg-body-secondary">
                                        {header?.name}
                                    </th>
                                ))
                                : ''
                        }
                        <th className="bg-body-secondary" style={{width: "250px"}}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Data && Data?.length > 0 ? Data?.map((item, index) => (
                            <tr className="align-middle" key={item?.id}>
                                {
                                    TableHeaders && TableHeaders?.length > 0 ?
                                        TableHeaders?.map(header => (

                                            <td key={header?.id}>
                                                <div className="small text-body-secondary">
                                                    {
                                                        header?.datatype == 'text' ? item[header?.key] :
                                                            header?.datatype == 'image' ?
                                                                <td className="text-center">
                                                                    <div className="avatar avatar-md"><img className="avatar-img" src={`${API_URL}/${item[header?.key]}`} alt={header?.name} /><span className="avatar-status bg-success"></span></div>
                                                                </td> :
                                                                header?.datatype == 'bool' ? item[header?.key] ? 'YES' : 'NO' : 
                                                                header?.datatype == 'file' ? <a href={item[header?.key]} target="_blank">Download</a> : 
                                                                header?.datatype == 'link' ? <a href={item[header?.key]} target="_blank">Visit</a> : 
                                                                item[header?.key]
                                                    }
                                                </div>
                                                {/* <div className="fw-semibold text-nowrap">10 sec ago</div> */}
                                            </td>
                                        ))
                                        : ''
                                }
                                <td>
                                <div className="d-flex justify-content-between">
                                    {
                                        Actions && Actions?.length > 0 ?
                                            Actions?.map(action => (
                                                <>
                                                {
                                                    action?.type == 'TOGGLE' ? 
                                                    <div className="small text-body-secondary">{action?.name}</div>
                                                    : 
                                                    action?.type == 'BUTTON' ? 
                                                    <button class={`btn btn-sm btn-${action?.color}`} onClick={e => window.location.href = `${Location.pathname}${action?.ajaxUrl}${item?.id}`}>{action?.name}</button>
                                                    : ''
                                                }
                                                {/* <div className="fw-semibold text-nowrap">10 sec ago</div> */}
                                                </>
                                            ))
                                            : ''
                                    }
                                </div>
                                </td>

                            </tr>
                        )) : ''
                    }
                </tbody>
            </table>
        </div>
    )
}
