import React, { useEffect, useState } from 'react';
import { postAPI } from '../../../../utils/Api';

const SubMenus = ({ menu }) => (
    <>
        {menu?.SubMenu?.length > 0 && (
            <ul>
                {menu.SubMenu.map((submenu) => (
                    <li className="nav-item" key={submenu?.mid}>
                        <a className="nav-link" href={submenu?.Link}>
                            <span className="nav-icon">
                                <span className="nav-icon-bullet"></span>
                            </span>{" "}
                            {submenu?.Name}
                        </a>
                    </li>
                ))}
            </ul>
        )}
    </>
);

const Menus = ({ element }) => (
    <>
        {element?.Menu?.length > 0 && (
            <ul>
                {element.Menu.map((menu) =>
                    menu.SubMenu.length === 0 ? (
                        <li className="nav-item" key={menu?.mid}>
                            <a className="nav-link" href={menu?.Link}>
                                {menu?.Name}
                                <span className="badge badge-sm bg-info ms-auto">NEW</span>
                            </a>
                        </li>
                    ) : (
                        <MenuGroup Menu={menu} key={menu?.mid} />
                    )
                )}
            </ul>
        )}
    </>
);

const MenuGroup = ({ Menu }) => {
    const [Visible, setVisible] = useState(false);
    
    return (
        <li className={`nav-group ${Visible ? 'show' : ''}`} key={Menu?.mid}>
            <a className="nav-link nav-group-toggle" href="#" onClick={() => setVisible(!Visible)}>
                {Menu?.Name}
            </a>
            <ul className={`nav-group-items compact`}>
                <SubMenus menu={Menu} />
            </ul>
        </li>
    );
}

export default function SideBar() {
    const [SideBarData, setSideBarData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const form_data = {};
            const responseData = await postAPI('/Menu/list', form_data);

            if (responseData?.data?.length > 0) {
                const Section = [];
                
                for (const item of responseData.data) {
                    if (item.is_menu === 1) {
                        if (item.parent_section === 0) {
                            Section.push({
                                id: item.id,
                                Section: item.name,
                                Menu: [],
                            });
                        } else {
                            const menuItem = {
                                mid: item.id,
                                Name: item.name,
                                Link: item.link,
                                SubMenu: [],
                            };

                            const lastSection = Section[Section.length - 1];

                            if (lastSection) {
                                if (item.parent_menu > 0) {
                                    const parentMenu = lastSection.Menu.find(menu => menu.mid === item.parent_menu);
                                    if (parentMenu) {
                                        parentMenu.SubMenu.push(menuItem);
                                    } else {
                                        lastSection.Menu.push(menuItem);
                                    }
                                } else {
                                    lastSection.Menu.push(menuItem);
                                }
                            } else {
                                console.error("No last section found for menu item", menuItem);
                            }
                        }
                    } else {
                        // console.log(item.name)
                        Section.push({
                            id: item.id,
                            Section: item.name,
                            Menu: []
                        })
                    }
                }

                setSideBarData(Section);
                // console.log(JSON.stringify(Section, null, 2));
            }
        };

        fetchData();
    }, []);

    return (
        <div className="sidebar sidebar-dark sidebar-fixed border-end" id="sidebar">
            <ul className="sidebar-nav" data-coreui="navigation" data-simplebar>
                {SideBarData.length > 0 ? (
                    SideBarData.map((element) => (
                        <React.Fragment key={element?.id}>
                            <li className="nav-title">{element?.Section}</li>
                            <Menus element={element} />
                        </React.Fragment>
                    ))
                ) : (
                    ''
                )}
            </ul>
            <div className="sidebar-footer border-top d-none d-md-flex">
                <button className="sidebar-toggler" type="button" data-coreui-toggle="unfoldable"></button>
            </div>
        </div>
    );
}
